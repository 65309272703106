<template lang="pug">
.full-box.main-box(v-loading="loading")
  .customer-box
    label.blue-header 用水户信息
    .info-box
      DataInfo(
        v-if="show"
        :data="usewaterData"
        :fields="usewaterFormFields")
  .bgc-block
  .form-box
    DataformGroup(
      v-if="show"
      ref="form"
      v-model="formData"
      groupType="row"
      dot
      :colspanNum="3"
      :groupSplit="groupSplit"
      :groupTitle="groupTitle"
      :formFields="formFields")
      template(slot="form2")
        .invoice-box
          DataTable.full-box(
            v-if="billId"
            ref="table"
            :showFilter="false"
            :hasPage="false"
            :resource="`/invoice/invoiceInfo?billId=${billId}`"
            :columns="columns"
            :settingButtonList="[]"
            :transformResponse="handleTransformResponse")
</template>

<script>
import { mapActions } from 'vuex'
import { groupConfig, formFieldList } from '../config'
import { composePromise, renderRelationColumns } from '@/utils/common.js'

export default {
  name: 'BillDetail',
  computed: {
    billId () { return this.$route.params.billId }
  },
  data () {
    return {
      loading: false,
      usewaterData: {},
      usewaterFormFields: [
        {
          name: 'usewaterCode',
          label: '用水户号'
        },
        {
          name: 'customerCode',
          label: '用户编号'
        },
        {
          name: 'customerName',
          label: '用户姓名'
        },
        {
          name: 'usewaterAddress',
          label: '用水地址'
        },
        {
          name: 'usewaterPhone',
          label: '联系电话'
        },
        {
          name: 'billCycle',
          label: '缴费周期',
          relation: 'billCycle',
          render: { type: 'select' }
        },
        {
          name: 'usenatureId',
          label: '用水性质',
          relation: 'usenature',
          render: { type: 'select' }
        },
        {
          name: 'usewaterAtts',
          label: '附件',
          render: { type: 'file', showType: 'image', colSpan: 3 }
        }
      ],
      show: false,
      formData: {},
      groupSplit: groupConfig.split,
      groupTitle: groupConfig.title,
      formFields: formFieldList,
      columns: [
        {
          name: 'invoiceStatus',
          label: '发票状态',
          minWidth: 80,
          relation: 'invoiceStatus',
          render: { type: 'select' }
        }, {
          name: 'invoiceCode',
          label: '发票编号',
          minWidth: 100
        }, {
          name: 'invoiceNo',
          label: '发票号码',
          minWidth: 100
        },
        {
          name: 'invoiceName',
          label: '开票名称',
          minWidth: 120
        }, {
          name: 'invoiceBank',
          label: '开票银行',
          minWidth: 100
        },
        {
          name: 'invoicePhone',
          label: '开票联系电话',
          minWidth: 120
        },
        {
          name: 'invoiceTax',
          label: '纳税人识别号',
          minWidth: 120
        },
        {
          name: 'invoiceBankAccount',
          label: '银行账号',
          minWidth: 170
        }, {
          name: 'address',
          label: '联系地址',
          minWidth: 150
        }, {
          name: 'invoiceTime',
          label: '开票时间',
          minWidth: 140,
          render: { type: 'datetime' }
        }, {
          name: 'deleteTime',
          label: '作废时间',
          minWidth: 140,
          render: { type: 'datetime' }
        }
      ]
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    handleTransformResponse (response) {
      const data = response.data || []
      response.data = {
        total: data.length,
        data: data
      }
      return response
    },
    /**
     * 获取账单详情
     * @param {*} { billId: 账单id }
     * @return {*} { bill: 账单信息 }
     */
    getBillById ({ billId }) {
      return new Promise((resolve, reject) => {
        this.$get({
          url: `/bill/get/${billId}`
        })
          .then(res => {
            if (!res) {
              reject(res)
            } else {
              resolve({ bill: res.data })
            }
          })
          .catch(reject)
      })
    },
    /**
     * 获取账单对应抄读记录信息
     * @param {*} { bill: 账单信息 }
     * @return {*} { bill: 账单信息, record: 抄读记录信息 }
     */
    getRecordById (data) {
      return new Promise((resolve, reject) => {
        this.$get({
          url: `/meterReading/get/${data.bill.meterReadingId}`
        })
          .then(res => {
            if (!res) {
              reject(res)
            } else {
              resolve({ ...data, record: res.data })
            }
          })
          .catch(reject)
      })
    },
    /**
     * 获取账单对应用水户信息
     * @param {*} { bill: 账单信息, record: 抄读记录信息 }
     * @return {*} { bill: 账单信息, record: 抄读记录信息, usewater: 用水户信息, meter: 水表信息 }
     */
    getUserwaterById (data) {
      return new Promise((resolve, reject) => {
        this.$get({
          url: `/usewater/get/${data.bill.usewaterId}`
        })
          .then(res => {
            if (!res) {
              reject(res)
            } else {
              const meter = res.data.meter
              const usewater = res.data
              delete usewater.meter
              resolve({ ...data, usewater, meter })
            }
          })
          .catch(reject)
      })
    },
    renderData ({ record, usewater, bill, invoice }) {
      this.usewaterData = usewater
      this.formData = {
        ...record,
        ...bill,
        ...invoice
      }
      this.show = true
      this.loading = false
    },
    getData () {
      const step = [
        this.renderData,
        this.getUserwaterById,
        this.getRecordById,
        this.getBillById
      ]
      this.loading = true
      composePromise(step)({ billId: this.$route.params.billId })
        .catch(e => {
          console.error(e)
          this.loading = false
        })
    },
    renderRelation () {
      this.getRelations(['readingStatus', 'meterCondition', 'meterStatus', 'usenature', 'billCycle', 'meterType', 'billPayStatus', 'payType', 'invoiceStatus'])
        .then(res => {
          renderRelationColumns(res, [this.usewaterFormFields, this.formFields, this.columns])
          // this.$nextTick(() => { this.show = true })
        })
    }
  },
  created () {
    this.renderRelation()
    this.getData()
  }
}
</script>

<style lang="sass" scoped>
.main-box
  display: flex
  flex-direction: row
  .customer-box
    width: 260px
    padding: 8px
    .info-box
      height: calc( 100% - 40px )
      overflow-y: auto
      padding-top: 8px
  .form-box
    width: calc( 100% - 268px )
    background-color: #fff
    overflow-y: auto
  .bgc-block
    width: 8px
    height: 100%
    background-color: #eceff0
  .invoice-box
    height: 360px
</style>
