import sum from 'lodash/sum'
// import sumBy from "lodash/sumBy"

const nameMap = new Map([
  ['bill', '账单信息'],
  ['meterReading', '抄表信息'],
  ['invoice', '开票信息']
])

export const formFieldList = [
  {
    name: 'billMonth',
    label: '账期',
    form: { tag: 'text' },
    group: 'bill'
  },
  {
    name: 'billNo',
    label: '账单编号',
    form: { tag: 'text' },
    group: 'bill'
  },
  {
    name: 'totalAmount',
    label: '总金额',
    form: { tag: 'text' },
    render: { type: 'padString', suffix: '元' },
    group: 'bill'
  },
  {
    name: 'waterAmount',
    label: '水费总额',
    form: { tag: 'text' },
    render: { type: 'padString', suffix: '元' },
    group: 'bill'
  },
  {
    name: 'sewageAmount',
    label: '污水费总额',
    form: { tag: 'text' },
    render: { type: 'padString', suffix: '元' },
    group: 'bill'
  },
  {
    name: 'waterResourceAmount',
    label: '水资源费总额',
    form: { tag: 'text' },
    render: { type: 'padString', suffix: '元' },
    group: 'bill'
  },
  {
    name: 'penaltyAmount',
    label: '滞纳金总额',
    form: { tag: 'text' },
    render: { type: 'padString', suffix: '元' },
    group: 'bill'
  },
  {
    name: 'penaltyDays',
    label: '滞纳天数',
    form: { tag: 'text' },
    group: 'bill'
  },
  {
    name: 'billCreateTime',
    label: '账单产生日期',
    form: { tag: 'text' },
    render: { type: 'date', timeType: 'YYYY-MM-DD HH:mm' },
    group: 'bill'
  },
  {
    name: 'billPayStatus',
    label: '缴费状态',
    relation: 'billPayStatus',
    form: { tag: 'text' },
    render: { type: 'select' },
    group: 'bill'
  },
  {
    name: 'payType',
    label: '缴费方式',
    relation: 'payType',
    form: { tag: 'text' },
    render: { type: 'select' },
    group: 'bill'
  },
  {
    name: 'payTime',
    label: '缴费日期',
    form: { tag: 'text' },
    render: { type: 'date' },
    group: 'bill'
  },
  {
    name: 'invoice',
    label: '已开票',
    form: { tag: 'text' },
    render: { type: 'yesOrNo' },
    group: 'bill'
  },
  {
    name: 'readingStatus',
    label: '抄表状态',
    relation: 'readingStatus',
    form: { tag: 'text' },
    render: { type: 'select' },
    group: 'meterReading'
  },
  {
    name: 'lastReadingNum',
    label: '上次抄表行码',
    form: { tag: 'text' },
    render: { type: 'padString', suffix: 'm³' },
    group: 'meterReading'
  },
  {
    name: 'estimate',
    form: { tag: 'text' },
    label: '估抄',
    render: { type: 'yesOrNo' },
    group: 'meterReading'
  },
  {
    name: 'meterCondition',
    label: '表况',
    relation: 'meterStatus',
    form: { tag: 'text' },
    render: { type: 'select' },
    group: 'meterReading'
  },
  {
    name: 'thisReadingNum',
    label: '本次抄表行码',
    form: { tag: 'text' },
    render: { type: 'padString', suffix: 'm³' },
    group: 'meterReading'
  },
  {
    name: 'quantity',
    label: '水量',
    form: { tag: 'text' },
    render: { type: 'padString', suffix: 'm³' },
    group: 'meterReading'
  },
  {
    name: 'readingStaffName',
    label: '抄表员',
    form: { tag: 'text' },
    group: 'meterReading'
  },
  {
    name: 'readingTime',
    label: '抄表时间',
    form: { tag: 'text', colSpan: 2 },
    render: { type: 'date', timeType: 'YYYY-MM-DD HH:mm' },
    group: 'meterReading'
  },
  {
    name: 'readingAttchs',
    label: '附件',
    form: { tag: 'text', colSpan: 3 },
    render: { type: 'file', showType: 'image' },
    group: 'meterReading'
  },
  {
    name: 'invoiceName',
    label: '开票名称',
    form: { tag: 'text' },
    group: 'invoice'
  }
]

const group = formFieldList.reduce((res, field) => {
  if (!res[field.group]) res[field.group] = []
  res[field.group].push(field.name)
  return res
}, {})

export const groupConfig = Object.keys(group).reduce((res, item) => {
  res.title.push(nameMap.get(item))
  res.split.push(group[item])
  return res
}, { title: [], split: [] })

const columnWidth = [150, 95, 75, 75, 145]
const topMargin = 30
const leftMargin = 28
const tableWidth = sum(columnWidth)
const tdHeight = 28
const tableConfig = [
  {
    height: 54,
    columns: [{
      title: '用户账单明细',
      fontSize: 18,
      height: 54,
      width: tableWidth,
      textAlign: 'center'
    }]
  },
  {
    height: tdHeight,
    columns: [{
      title: '用户编码:',
      field: 'customerCode',
      data: '-',
      width: tableWidth,
      titleWidth: 68,
      height: tdHeight,
      textAlign: 'left'
    }]
  },
  {
    height: tdHeight,
    columns: [{
      title: '用户名:',
      field: 'customerName',
      data: '-',
      width: tableWidth,
      titleWidth: 56,
      height: tdHeight,
      textAlign: 'left'
    }]
  },
  {
    height: tdHeight,
    columns: [{
      title: '地址:',
      field: 'address',
      data: '-',
      width: sum(columnWidth.slice(0, 4)),
      titleWidth: 44,
      height: tdHeight,
      textAlign: 'left'
    }, {
      title: '用水性质:',
      field: 'usenature',
      data: '-',
      width: columnWidth[4],
      titleWidth: 60,
      height: tdHeight,
      textAlign: 'left'
    }]
  },
  {
    height: tdHeight,
    columns: [{
      title: '用水收费内容',
      width: sum(columnWidth.slice(0, 2)),
      titleWidth: 44,
      height: tdHeight,
      textAlign: 'left'
    }, {
      title: '单价（元）',
      width: sum(columnWidth.slice(2, 4)),
      titleWidth: 60,
      height: tdHeight,
      textAlign: 'center'
    }, {
      title: '用水（吨）',
      width: columnWidth[4],
      titleWidth: 60,
      height: tdHeight,
      textAlign: 'center'
    }]
  },
  {
    height: tdHeight,
    width: sum(columnWidth.slice(0, 2)),
    columns: [{
      title: '上月水表行码:',
      field: 'lastReadingNum',
      data: '-',
      width: sum(columnWidth.slice(0, 2)),
      titleWidth: 44,
      height: tdHeight,
      textAlign: 'left'
    }, {
      title: '',
      field: 'price',
      data: '-',
      width: sum(columnWidth.slice(2, 4)),
      titleWidth: 60,
      height: tdHeight * 2,
      textAlign: 'center'
    }, {
      title: '',
      field: 'quantity',
      data: '-',
      width: columnWidth[4],
      titleWidth: 60,
      height: tdHeight * 2,
      textAlign: 'center'
    }]
  },
  {
    height: tdHeight,
    columns: [{
      title: '本月水表行码:',
      field: 'thisReadingNum',
      data: '-',
      width: sum(columnWidth.slice(0, 2)),
      titleWidth: 44,
      height: tdHeight,
      textAlign: 'left'
    }]
  },
  {
    height: tdHeight,
    columns: [{
      title: '本月用水收费明细量',
      height: tdHeight,
      width: tableWidth,
      textAlign: 'left'
    }]
  },
  {
    height: tdHeight,
    columns: [{
      title: '水费（元）:',
      field: 'waterAmount',
      data: '-',
      height: tdHeight,
      width: tableWidth,
      textAlign: 'left'
    }]
  },
  {
    height: tdHeight,
    columns: [{
      title: '污水处理费（元）:',
      field: 'sewageAmount',
      data: '-',
      height: tdHeight,
      width: tableWidth,
      textAlign: 'left'
    }]
  },
  {
    height: tdHeight,
    columns: [{
      title: '水资源税（元）:',
      field: 'waterResourceAmount',
      data: '-',
      height: tdHeight,
      width: tableWidth,
      textAlign: 'left'
    }]
  },
  {
    height: tdHeight,
    columns: [{
      title: '用水量定额（吨）',
      height: tdHeight,
      width: columnWidth[0],
      textAlign: 'left'
    }, {
      title: '',
      field: 'ratedQuantity',
      data: '-',
      height: tdHeight,
      width: sum(columnWidth.slice(1, 5)),
      textAlign: 'left'
    }]
  },
  {
    height: tdHeight,
    width: sum(columnWidth.slice(1, 5)),
    widthLeftMargin: columnWidth[0],
    columns: [{
      title: '超出定额水量（吨）',
      height: tdHeight * 3,
      width: columnWidth[0],
      textAlign: 'left'
    }, {
      title: '超出部分（吨）',
      height: tdHeight,
      width: columnWidth[1],
      textAlign: 'center'
    }, {
      title: '单价（元）',
      height: tdHeight,
      width: columnWidth[2],
      textAlign: 'center'
    }, {
      title: '水量（吨）',
      height: tdHeight,
      width: columnWidth[3],
      textAlign: 'center'
    }, {
      title: '金额（元）',
      height: tdHeight,
      width: columnWidth[4],
      textAlign: 'center'
    }]
  },
  {
    height: tdHeight,
    width: sum(columnWidth.slice(1, 5)),
    widthLeftMargin: columnWidth[0],
    columns: [{
      title: '',
      field: '2ndQuantityMax',
      data: '-',
      height: tdHeight,
      width: columnWidth[1],
      widthLeftMargin: columnWidth[0],
      textAlign: 'center',
      formatter: (title, value, options, data, target) => {
        if (data['2ndQuantityMax'] && data['2ndQuantityMin']) {
          return `${data['2ndQuantityMin']}-${data['2ndQuantityMax']}`
        } else {
          return '-'
        }
      }
    }, {
      title: '',
      field: '2ndBasePrice',
      data: '-',
      height: tdHeight,
      width: columnWidth[2],
      textAlign: 'center'
    }, {
      title: '',
      field: '2ndQuantity',
      data: '-',
      height: tdHeight,
      width: columnWidth[3],
      textAlign: 'center'
    }, {
      title: '',
      field: '2ndAmount',
      data: '-',
      height: tdHeight,
      width: columnWidth[4],
      textAlign: 'center'
    }]
  },
  {
    height: tdHeight,
    columns: [{
      title: '',
      field: '3rdQuantityMin',
      data: '-',
      height: tdHeight,
      width: columnWidth[1],
      widthLeftMargin: columnWidth[0],
      textAlign: 'center',
      formatter: (title, value, options, data, target) => {
        return value ? `${value}以上(含)` : '-'
      }
    }, {
      title: '',
      field: '3rdBasePrice',
      data: '-',
      height: tdHeight,
      width: columnWidth[2],
      textAlign: 'center'
    }, {
      title: '',
      field: '3rdQuantity',
      data: '-',
      height: tdHeight,
      width: columnWidth[3],
      textAlign: 'center'
    }, {
      title: '',
      field: '3rdAmount',
      data: '-',
      height: tdHeight,
      width: columnWidth[4],
      textAlign: 'center'
    }]
  },
  {
    height: tdHeight,
    columns: [{
      title: '本月应缴水费（元）（小计）:',
      field: 'totalAmount',
      data: '-',
      height: tdHeight,
      width: tableWidth,
      textAlign: 'left'
    }]
  },
  {
    height: 116,
    columns: [{
      title: '特别提示',
      height: 116,
      width: columnWidth[0],
      textAlign: 'center'
    }, {
      title: '',
      field: 'msg',
      height: 116,
      width: sum(columnWidth.slice(1, 5)),
      textAlign: 'left',
      elType: 'longText',
      lineHeight: 15
    }]
  }
]
const valueFormatter = (title, value, options, templateData, target) => value || '-'

const createTableLine = (tableConfig) => {
  let calcHeight = topMargin
  return tableConfig.reduce((res, row) => {
    // 竖线+文字
    let calcColumnWidth = leftMargin
    row.columns.forEach(column => {
      // 标题
      if (column.widthLeftMargin) calcColumnWidth += column.widthLeftMargin
      const fontSize = column.fontSize || 12
      const titleWidth = column.title.length * fontSize
      const label = {
        options: {
          left: calcColumnWidth + (column.textAlign === 'left' ? 8 : 0),
          top: calcHeight,
          height: column.height || tdHeight,
          width: column.field ? titleWidth : column.width,
          title: column.title,
          fontSize,
          textAlign: column.textAlign || 'left',
          fontWeight: 'bold',
          textContentVerticalAlign: 'middle'
        },
        printElementType: { type: 'text' }
      }
      res.push(label)

      // 值
      if (column.field) {
        const valueText = {
          options: {
            field: column.field,
            left: calcColumnWidth + titleWidth + 4,
            top: calcHeight,
            height: column.height || tdHeight,
            width: column.width - titleWidth,
            fontSize,
            textAlign: column.textAlign,
            textContentVerticalAlign: 'middle',
            formatter: column.formatter || valueFormatter
          },
          printElementType: { type: column.elType || 'text' }
        }
        if (column.lineHeight) valueText.options.lineHeight = column.lineHeight
        res.push(valueText)
      }

      // 竖线
      calcColumnWidth += column.width
      const vline = {
        options: {
          left: calcColumnWidth,
          top: calcHeight,
          height: column.height || tdHeight,
          width: 9
        },
        printElementType: { type: 'vline' }
      }
      const firstVline = {
        options: {
          left: leftMargin,
          top: calcHeight,
          height: column.height || tdHeight,
          width: 9
        },
        printElementType: { type: 'vline' }
      }
      res.push(vline)
      res.push(firstVline)
    })
    calcHeight += row.height
    // 横线
    const hline = {
      options: {
        left: leftMargin + (row.widthLeftMargin || 0),
        top: calcHeight,
        height: 9,
        // 线体长度
        width: row.width || tableWidth
      },
      printElementType: { type: 'hline' }
    }
    res.push(hline)
    return res
  }, [{
    options: {
      left: leftMargin,
      top: topMargin,
      height: 9,
      // 线体长度
      width: tableWidth
    },
    printElementType: { type: 'hline' }
  }, {
    options: {
      // 打印日期
      field: 'printDate',
      left: leftMargin + 8, // 左边距 = 表格左边距 + 8
      top: topMargin + 54 - 12 - 8, // 上边距 = 表格上边距 + 首行高度 - 文字大小 - 8
      height: 12,
      width: 100,
      fontSize: 12,
      textAlign: 'left',
      textContentVerticalAlign: 'middle'
    },
    printElementType: { type: 'text' }
  }])
}

export const calcTemplate = {
  panels: [{
    index: 0,
    paperType: 'A4',
    height: 297,
    width: 210,
    paperHeader: 43.5,
    paperFooter: 801,
    printElements: createTableLine(tableConfig),
    paperNumberLeft: 565,
    paperNumberTop: 819
  }]
}
